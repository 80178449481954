/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ol: "ol",
    li: "li",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "You have permission to use our Service Offerings for one Trial Period, subject to the following terms:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Eligible for a Trial Period are only Customers currently not subscribing to our Service Offerings."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "The Trial period is for thirty (30) days."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "The Trial period is offered to you at no charge."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "To access the Services, you have to create an account and provide valid billing information."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "You can cancel the Trial Period by unsubscribing at any time via “My Account”."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you do not cancel the Trial Period before the Trial Period ends, you agree that you:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "subscribe to the Services you selected for the Trial Period."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "will be billed for the Services you selected for the Trial Period."), "\n"), "\n"), "\n"), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
